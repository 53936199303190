import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';

import { Button, Flex, Image, Label } from '@zeal/web-ui';

import NotFoundImage from '@zeal/web-ui/src/assets/404.svg';

export default function NotFound() {
	const { t } = useTranslation('common');

	const history = useHistory();

	const goToHomePage = useCallback(() => {
		history.push('/');
	}, []);

	return (
		<Flex
			isColumn
			gap="xl"
			justify="center"
			align="center"
			className="max-w-xl mx-auto text-center min-h-screen py-4"
		>
			<Flex isColumn gap="base" justify="center" align="center">
				<Label variant="black" weight="bold" size="5xl">
					{t('page_not_found')}
				</Label>
				<Label variant="black" size="lg">
					{t('not_found_msg')}
				</Label>
				<Button variant="primary" onClick={goToHomePage}>
					{t('go_home')}
				</Button>
			</Flex>
			<Image src={NotFoundImage} />
		</Flex>
	);
}
