import React, { createElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
	EMagicLinkType,
	MagicLinkStepEnum,
	TApiGetEmaiResponse,
	TMagicLinkForm,
} from '@app/MagicLinkLogin/types';
import { Flex, ParentShimmer } from '@zeal/web-ui';

import FormHeader from './FormHeader';
import EmailRegistration from './EmailRegistration';
import CodeVerification from './CodeVerification';
import CreatePassword from './CreatePassword';
import { useMagicLinkFormContext } from './FormContext';
import { useParams } from 'react-router-dom';
import { useGetMagicLinkEmail } from '@app/data/MagicLink/useMagicLink';
import openToaster from '@app/Toastr/openToastr';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import VerificationSent from './VerificationSent';
import MainCard from '../MainCard';
import { useFetchTranslation } from '@app/hooks';

const formContent: PartialRecord<MagicLinkStepEnum, React.FC> = {
	[MagicLinkStepEnum.EmailRegistration]: EmailRegistration,
	[MagicLinkStepEnum.CodeVerification]: CodeVerification,
	[MagicLinkStepEnum.CreatePassword]: CreatePassword,
	[MagicLinkStepEnum.VerificationSent]: VerificationSent,
};

export default function FormContent() {
	const { token } = useParams<{ token: string }>();

	const { isTranslationsLoading } = useFetchTranslation('magicLink');

	const { t } = useTranslation('magicLink');

	const formSchema = yup.object({
		email: yup.string().email().required(t('email_error')),
		password: yup.string().required(t('password_error')).min(8),
		password_confirmation: yup
			.string()
			.required(t('password_confirmation_missing'))
			.oneOf([yup.ref('password'), null], t('password_confirmation_error')),
		accepted_terms: yup
			.boolean()
			.oneOf([true], t('terms_and_conditions_error')),
	});

	const formMethods = useForm<TMagicLinkForm>({
		defaultValues: {
			token: '',
			email: '',
			code: '',
			password: '',
			password_confirmation: '',
			accepted_terms: false,
		},
		resolver: yupResolver(formSchema),
	});
	const { setValue } = formMethods;
	const { step, setStep } = useMagicLinkFormContext();
	const [isExpired, setIsExpired] = useState(false);

	const { data, isLoading, error } = useGetMagicLinkEmail(token, {
		enabled: !isExpired,
	});

	useEffect(() => {
		if (data) {
			setValue('type', data.type);
			if (data.type === EMagicLinkType.REGISTRATION) {
				setValue('email', data.email);
				setStep(MagicLinkStepEnum.CreatePassword);
			}
		}
	}, [data]);

	useEffect(() => {
		setValue('token', token);
	}, [token]);

	useEffect(() => {
		// if token is expired
		if (error && !isLoading) {
			openToaster({
				message: t('expired_magic_link'),
				variant: 'error',
			});
			setIsExpired(true);
		}
	}, [isLoading, error, t]);

	if (
		step === MagicLinkStepEnum.AccountVerified ||
		step === MagicLinkStepEnum.LoginToAccount
	) {
		return <></>;
	}

	return (
		<ParentShimmer isLoading={isLoading || !data || isTranslationsLoading}>
			<MainCard>
				<Flex className="p-12" isColumn gap="6" align="center">
					<FormHeader />
					<Flex gap="10" isColumn justify="center" align="center">
						<FormProvider {...formMethods}>
							{createElement(formContent[step] as React.FC)}
						</FormProvider>
					</Flex>
				</Flex>
			</MainCard>
		</ParentShimmer>
	);
}
