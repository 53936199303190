import { Button, Flex, Input } from '@zeal/web-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	useGetMagicLinkEmail,
	useSendMagicLinkCode,
} from '@app/data/MagicLink/useMagicLink';
import { useParams } from 'react-router-dom';
import { useMagicLinkFormContext } from './FormContext';

export default function EmailRegistration() {
	const { token } = useParams<{ token: string }>();
	const { t } = useTranslation('magicLink');

	const { data, isLoading } = useGetMagicLinkEmail(token);

	const { goToNextStep, setErrors } = useMagicLinkFormContext();

	const { doSendMagicLinkCode } = useSendMagicLinkCode({
		onSuccess: () => {
			goToNextStep();
		},
		onError: (
			_: any,
			__: any,
			context: { parsedError: Record<string, any> }
		) => {
			if (context?.parsedError) {
				setErrors(context.parsedError);
			}
		},
	});

	return (
		<>
			<Flex gap="6">
				<Input
					label={t('email')}
					name="email"
					type="email"
					className="w-full"
					style={{
						width: '504px',
					}}
					value={data?.email}
					onChange={() => {}}
					disabled
					startIconProps={{
						iconName: 'envelope',
					}}
					placeholder={t('email_placeholder')}
				/>
			</Flex>
			<Button
				variant="primary"
				size="lg"
				className="w-full text-center justify-center"
				isLoading={isLoading}
				disabled={!data?.email}
				style={{
					width: '504px',
				}}
				onClick={() => {
					doSendMagicLinkCode({ token });
				}}
			>
				{t('send')}
			</Button>
		</>
	);
}
