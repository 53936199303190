import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
	useSendMagicLinkCode,
	useVerifyMagicLinkCode,
} from '@app/data/MagicLink/useMagicLink';
import { TMagicLinkForm } from '@app/MagicLinkLogin/types';
import { Button, Flex, Label, OTPInput, palette } from '@zeal/web-ui';
import openToaster from '@app/Toastr/openToastr';
import { useMagicLinkFormContext } from './FormContext';
import { convertSecondsToTime } from '@zeal/zeal-lib';

export default function CodeVerification() {
	const { t } = useTranslation('magicLink');

	const [seconds, setSeconds] = useState(60);

	const { setValue, watch } = useFormContext<TMagicLinkForm>();
	const { goToNextStep, setErrors } = useMagicLinkFormContext();

	const code = watch('code');
	const token = watch('token');

	const { doVerifyMagicLinkCode, isLoading: isVerifyingCodeLoading } =
		useVerifyMagicLinkCode({
			onSuccess: () => {
				goToNextStep();
			},
			onError: (
				_: any,
				__: any,
				context: { parsedError: Record<string, any> }
			) => {
				openToaster({
					message: t('wrong_code_error'),
					variant: 'error',
				});
				if (context?.parsedError) {
					setErrors(context.parsedError);
				}
			},
		});

	const onConfirmButtonClick = useCallback(() => {
		if (code?.length < 4) {
			openToaster({
				message: t('missing_code_error'),
				variant: 'error',
			});
			return;
		}

		doVerifyMagicLinkCode({ token, code });
	}, [doVerifyMagicLinkCode, token, code, t]);

	const { doSendMagicLinkCode, isLoading: isResendingCodeLoading } =
		useSendMagicLinkCode({
			onSuccess: () => {
				setSeconds(60);
			},
			onError: (
				_: any,
				__: any,
				context: { parsedError: Record<string, any> }
			) => {
				if (context?.parsedError) {
					setErrors(context.parsedError);
				}
			},
		});

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds, setSeconds]);

	const timeDisplay = convertSecondsToTime(seconds);

	return (
		<>
			<Flex justify="center" align="center">
				<OTPInput
					onChange={(value: string) => {
						setValue('code', value);
					}}
					value={code}
					inputsGap="24px"
					numInputs={4}
					inputType="number"
					inputStyle={{
						textIndent: '20%',
					}}
					showUnderscore
				/>
			</Flex>
			<Flex isColumn gap="4" align="center">
				<Button
					variant="primary"
					size="lg"
					className="w-full text-center justify-center"
					style={{
						width: '504px',
					}}
					isLoading={isVerifyingCodeLoading}
					onClick={onConfirmButtonClick}
				>
					{t('confirm')}
				</Button>
				<Button
					variant={seconds === 0 ? 'primary' : 'ghost'}
					size="lg"
					className="w-full text-center justify-center"
					style={{
						width: '504px',
						backgroundColor: 'transparent',
						color: seconds === 0 ? palette.text.default : palette.text.disabled,
						border: `1px solid ${
							seconds === 0 ? palette.border.default : palette.border.disabled
						}`,
						borderRadius: '8px',
					}}
					isLoading={isResendingCodeLoading}
					disabled={seconds !== 0}
					onClick={() => {
						doSendMagicLinkCode({ token });
					}}
					iconProps={{
						iconName: 'refresh',
					}}
				>
					{t('resend_code')}
				</Button>
				{seconds !== 0 ? (
					<Flex align="center" justify="center" gap="1">
						<Label.Mid300 variant="subdued">
							{t('resend_code_timer_text')}
						</Label.Mid300>
						<Label.Mid300 variant="interactive">{timeDisplay}</Label.Mid300>
					</Flex>
				) : (
					<div style={{ height: '24px' }}></div>
				)}
			</Flex>
		</>
	);
}
