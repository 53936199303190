import { Avatar, Flex, Label } from '@zeal/web-ui';
import React from 'react';
import {
	VerticalDivider,
	StyledAvatarContainer,
	StyledAvatarLabel,
} from '../StyledComponents';
import ZealPoints from '../ZealPoints';
import DashboardDropdown from '../DashboardDropdown';

type NavBarAvatarProps = {
	displayName?: string;
	displayAccessLevel?: string;
	showCredits?: boolean;
};

const NavBarAvatarLayout = (props: NavBarAvatarProps) => {
	const { displayAccessLevel, displayName, showCredits } = props;
	return (
		<StyledAvatarContainer gap="5" align="center">
			<Flex align="center" gap="3" className="overflow-hidden">
				<Avatar username={displayName ?? '?'} hideUsername />
				<Flex gap="0" isColumn={true} className={'text overflow-hidden'}>
					<StyledAvatarLabel size="md" weight="semiBold">
						{displayName}
					</StyledAvatarLabel>
					<Label.Thin200 variant={'subdued'}>
						{displayAccessLevel}
					</Label.Thin200>
				</Flex>
			</Flex>
			{showCredits && (
				<>
					<VerticalDivider />

					<ZealPoints />
				</>
			)}
			<DashboardDropdown />
		</StyledAvatarContainer>
	);
};

export default NavBarAvatarLayout;
