import React, { useMemo } from 'react';
import DashboardLogoLayout from './DashboardLogoLayout';
import { Image } from '@zeal/web-ui';
import { useLoggedIn } from '@app/ACL/useLogin';
// @ts-ignore
import cardlink from './logos/cardlink-logo.svg';
// @ts-ignore
import free from './logos/free-logo.svg';
// @ts-ignore
import business from './logos/business-logo.svg';
import { useAppState } from '@app/store/appStore';
import { ImageWrapper } from '../StyledComponents';
import { useBusinessInfo } from '@app/BusinessAccount/useBusinessInfo';
import NavbarInitials from './NavbarInitials';

const BusinessDashboardLogo = () => {
	const { merchantType } = useLoggedIn();
	const { customAppName } = useAppState();
	const { data } = useBusinessInfo();
	const photoLink = data?.business?.photo;

	const customAppImage = useMemo(
		() => (
			<ImageWrapper>
				<Image
					rounded="lg"
					width={48}
					height={48}
					src={photoLink}
					className="max-w-max"
				/>
			</ImageWrapper>
		),
		[photoLink]
	);

	const appLogo =
		photoLink != null ? (
			customAppImage
		) : (
			<NavbarInitials username={customAppName} />
		);

	const tierImage = useMemo(() => {
		switch (merchantType) {
			case 'freemium':
				return <Image src={free} alt="Free Tier" />;
			case 'cardlink':
				return <Image src={cardlink} alt="Cardlink Tier" />;
			case 'premium':
				return <Image src={business} alt="Business Tier" />;
			default:
				return <></>;
		}
	}, [merchantType]);

	return (
		<DashboardLogoLayout
			Tierlogo={tierImage}
			appLogo={appLogo}
			currentAppName={customAppName ?? 'Zeal'}
		/>
	);
};

export default BusinessDashboardLogo;
