import {
	Button,
	Flex,
	ControlledCheckbox,
	ControlledInput,
	Label,
} from '@zeal/web-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { TApiError } from '../../../QueryClient/types';
import { useTranslation } from 'react-i18next';
import { MagicLinkStepEnum, TLoginForm } from '../../types';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useLogin } from '@app/ACL/useLogin';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormHeader from './FormHeader';
import MainCard from '../MainCard';
import { useQueryString } from '@zeal/zeal-lib';
import { useMagicLinkVerifyEmail } from '@app/data/MagicLink/useMagicLink';
import { useMagicLinkFormContext } from './FormContext';
import openToaster from '@app/Toastr/openToastr';
import { useFetchTranslation } from '@app/hooks';
import { InlineLoading } from '@zeal/web-ui';

export default function Login() {
	const [queryState] = useQueryString();
	const { token } = queryState;
	const history = useHistory();
	const [errorToastId, setErrorToastId] = useState<string | null>(null);

	const { isTranslationsLoading } = useFetchTranslation('magicLink');

	const [mailSent, setMailSent] = useState(false);

	const { t } = useTranslation('magicLink');

	const { setStep } = useMagicLinkFormContext();

	const formSchema = yup.object({
		email: yup.string().email().required(t('email_error')),
		password: yup.string().required(t('password_error')).min(8),
	});

	const formMethods = useForm<TLoginForm>({
		defaultValues: {
			email: '',
			password: '',
			keepSignIn: false,
		},
		resolver: yupResolver(formSchema),
	});
	const { handleSubmit } = formMethods;

	const { doMagicLinkVerifyEmail, isLoading: isVerifyLoading } =
		useMagicLinkVerifyEmail({
			onError: () => {
				openToaster({
					message: t('invalid_or_expired_token'),
					variant: 'error',
				});
			},
		});

	useEffect(() => {
		if (token) {
			setStep(MagicLinkStepEnum.LoginToAccount);
			if (!isVerifyLoading && !mailSent) {
				setMailSent(true);
				doMagicLinkVerifyEmail({ token });
			}
		} else {
			setStep(MagicLinkStepEnum.AccountVerified);
		}
	}, []);

	const { doLogin, isLoading } = useLogin({
		onSuccess: () => {
			toast.dismiss();
			setTimeout(() => {
				history.push('/');
			}, 10);
		},
		onError: ({ toastId }: { toastId: string }) => {
			setErrorToastId(toastId);
		},
	});

	const handleLogin = useCallback(
		(data: TLoginForm) => {
			doLogin(data);

			if (errorToastId) {
				toast.dismiss(errorToastId);
			}
		},
		[doLogin, t, errorToastId]
	);

	const openForgotPassword = useCallback(() => {
		history.push('/forgot-password');
	}, [history]);

	if (isTranslationsLoading) {
		return <InlineLoading label=" " />;
	}

	return (
		<MainCard>
			<FormProvider {...formMethods}>
				<Flex className="p-12" isColumn gap="6" align="center">
					<FormHeader />
					<Flex gap="6" isColumn>
						<ControlledInput<TLoginForm>
							label={t('email')}
							name="email"
							type="email"
							className="w-full"
							style={{
								width: '504px',
							}}
							startIconProps={{
								iconName: 'envelope',
							}}
							placeholder={t('email_placeholder')}
						/>
						<ControlledInput<TLoginForm>
							label={t('password')}
							name="password"
							type="password"
							className="w-full"
							style={{
								width: '504px',
							}}
							startIconProps={{
								iconName: 'lockKeyhole',
							}}
							placeholder={t('password_placeholder')}
							autoComplete="new-password"
						/>
					</Flex>
					<Flex align="center" justify="between" className="w-full">
						<Flex align="center" gap="2">
							<ControlledCheckbox<TLoginForm>
								name="keepSignIn"
								className="w-6 h-6"
							/>
							<Label.Thin300>{t('remember_me')}</Label.Thin300>
						</Flex>
						<Flex align="center" gap="2">
							<Button
								variant="transparent"
								hasFocus={false}
								className="!m-0 !p-0"
								onClick={openForgotPassword}
							>
								<Label.Mid300 variant="secondary">
									{t('forgot_password')}
								</Label.Mid300>
							</Button>
						</Flex>
					</Flex>
					<Button
						variant="primary"
						size="lg"
						className="w-full text-center justify-center"
						isLoading={isLoading}
						style={{
							width: '504px',
						}}
						onClick={handleSubmit(handleLogin)}
					>
						{t('login')}
					</Button>
					<Flex align="center" gap="1">
						<Label.Thin300>{t('by_logging_in')}</Label.Thin300>
						<a
							href="https://zealpay.app/en/term-and-conditions/#introduction"
							target="_blank"
							rel="noopener noreferrer"
						>
							<Label.Thin300 variant="interactive">
								{t('zeals_terms_and_conditions')}
							</Label.Thin300>
						</a>
					</Flex>
				</Flex>
			</FormProvider>
		</MainCard>
	);
}
