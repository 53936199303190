import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Label, ZealSvg, Tooltip } from '@zeal/web-ui';
import {
	DashboardWrapper,
	ImageWrapper,
	VerticalDivider,
} from '../StyledComponents';

type DashboardLogoProps = {
	currentAppName: string;
	appLogo?: React.ReactNode;
	Tierlogo?: React.ReactNode;
};

export default function DashboardLogoLayout(props: DashboardLogoProps) {
	const { currentAppName, appLogo, Tierlogo } = props;

	const zealImage = useMemo(
		() => <ImageWrapper>{ZealSvg.zealIcon}</ImageWrapper>,
		[]
	);

	return (
		<DashboardWrapper align="center" justify="between" gap="8">
			<Tooltip tooltipId="dashboard-logo-tooltip" title={currentAppName}>
				<Flex align="center" gap="4">
					<Flex gap="3" align="center">
						<Link to="/">{appLogo ?? zealImage}</Link>
						<Label.Mid400 className="max-w-[145px] whitespace-nowrap text-ellipsis overflow-hidden">
							{currentAppName}
						</Label.Mid400>
					</Flex>
					{Tierlogo}
				</Flex>
			</Tooltip>
			<VerticalDivider />
		</DashboardWrapper>
	);
}
