import { TPathOptions } from '../App/types/types';

const promoCodesPathOptions: TPathOptions = {
	apiKey: 'promo_code',
	path: '/promocodes',
	label: 'promo_codes',
	iconName: 'badgePercent',
	menuOrder: 8,
	ownApp: false,
	subMenu: [
		{
			label: 'promocodes',
			path: '/promocodes/list',
		},
		{
			label: 'promocodes_report',
			path: '/promocodes/report',
		},
	],
};

export default promoCodesPathOptions;
