import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  Button,
  ControlledInput,
  Flex,
  Icon,
  InlineError,
  Card,
  InlineLoading,
  Label
} from "@zeal/web-ui";
import { useForgetPassword } from "../data/useForgotPassword";
import { sharedSchema } from "@zeal/zeal-lib";
export default function ForgetPasswordCard({ setEmailSent }) {
  const { t } = useTranslation("login");
  const schema = yup.object({
    email: sharedSchema.emailSchema({})
  });
  const formMethods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });
  const { handleSubmit } = formMethods;
  const { isError, isLoading, doForgetPassword } = useForgetPassword({
    onSuccess: () => {
      setEmailSent(true);
      return null;
    },
    disableToaster: true
  });
  const history = useHistory();
  const handleCancel = () => history.push("/");
  const forgetPasswordSubtitle = /* @__PURE__ */ React.createElement(React.Fragment, null, t("enter_email"), t("link"));
  const handleSubmitEmail = ({ email }) => {
    doForgetPassword(email);
  };
  return /* @__PURE__ */ React.createElement("form", { onSubmit: handleSubmit(handleSubmitEmail) }, /* @__PURE__ */ React.createElement(
    Card,
    {
      p: "12",
      className: "rounded-xl grid w-full",
      style: {
        minWidth: "400px"
      },
      shadow: "none"
    },
    /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "6" }, /* @__PURE__ */ React.createElement(Flex, { justify: "center" }, /* @__PURE__ */ React.createElement(
      Icon,
      {
        iconName: "lockKeyhole",
        size: "7x",
        variant: "regular",
        colorVariant: "secondary"
      }
    )), /* @__PURE__ */ React.createElement(
      Flex,
      {
        gap: "4",
        className: "text-center",
        isColumn: true,
        style: {
          width: "489px"
        }
      },
      /* @__PURE__ */ React.createElement(Label.Mid700, null, t("forget_password")),
      /* @__PURE__ */ React.createElement(Label.Mid400, { variant: "subdued" }, forgetPasswordSubtitle)
    ), /* @__PURE__ */ React.createElement(FormProvider, { ...formMethods }, /* @__PURE__ */ React.createElement(
      ControlledInput,
      {
        name: "email",
        type: "email",
        label: t("email_address", { ns: "common" }),
        placeholder: t("email"),
        inputName: "email",
        startIconProps: {
          iconName: "envelope"
        }
      }
    )), isError && /* @__PURE__ */ React.createElement(Label, { variant: "danger" }, "Email not verified or does not exist!"), /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "4" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "primary",
        className: "w-full",
        size: "lg",
        disabled: isLoading,
        type: "submit"
      },
      t("continue_btn")
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "ghost",
        className: "w-full",
        size: "lg",
        disabled: isLoading,
        onClick: handleCancel
      },
      t("cancel_btn")
    )))
  ));
}
